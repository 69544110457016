import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import Button from '@/components/atoms/Button/Button/Button';
import { MODAL_CUSTOM_GUIDELINE_CREATE } from '@/components/organisms/Modals/ModalConductor';
import { pushModal } from '@/redux/modals/actions';

import { PlusIcon } from '../../icons/PlusIcon';

export const AddGuidelineButton = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  return (
    <Button
      size="small"
      variant="secondary"
      onClick={(e: React.MouseEvent) => {
        e.stopPropagation();
        e.preventDefault();
        dispatch(pushModal(MODAL_CUSTOM_GUIDELINE_CREATE));
      }}
    >
      <PlusIcon color="currentColor" />
      {t('ai_agents.knowledge.add_guideline')}
    </Button>
  );
};
