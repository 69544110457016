import { useCallback, useEffect, useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import Box from '@mui/material/Box/Box';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { Resolver, useFieldArray, useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';

import AutocompleteNew from '@/components/atoms/AutocompleteNew/AutocompleteNew2';
import Button from '@/components/atoms/Button/Button/Button';
import Trash from '@/components/atoms/Icons/Trash';
import Select from '@/components/atoms/Select/Select';
import { MODAL_DIALOG_PREVIEW } from '@/components/organisms/Modals/ModalConductor';
import { renderDialogIcon } from '@/components/organisms/SubNav/TreeView/Item';
import useBrains from '@/hooks/useBrains';
import useDialogs from '@/hooks/useDialogs';
import { BrainReminder } from '@/models/brain';
import { pushModal } from '@/redux/modals/actions';
import { clearDialogNodes, setDialog } from '@/redux/nodes/actions';
import { setDialogId } from '@/redux/session/actions';
import { selectBrainId } from '@/redux/session/selectors';
import { reminderFormSchema } from '@/util/validator';

import { FormCard } from '../FormCard/FormCard';
import { NumberIcon } from '../NumberIcon/NumberIcon';

interface Props {
  number: number;
}

interface NodeOption {
  dialog_name: string;
  value: string;
  label: string;
  node_type: string;
}

interface FormReminder extends Pick<BrainReminder, 'reminder_id' | 'minutes'> {
  node: NodeOption;
}

interface Form {
  reminders: FormReminder[];
}

const FORM_ID = 'ai-agent-reminder';

// Default values for a new timer
const DEFAULT_TIMER_VALUES = {
  reminder_id: uuidv4(),
  minutes: null,
  node: null,
} as const;

export const Reminder = ({ number }: Props) => {
  // Redux selectors
  const brainId = useSelector(selectBrainId);
  const [fetchDialogId, setFetchDialogId] = useState<string | null>(null);

  // Custom hooks
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { detailedOptions, dialog } = useDialogs(brainId, fetchDialogId);
  const { brain, updateBrain } = useBrains(brainId);
  const { guidelines = {} } = brain || {};
  const { reminders = [] } = guidelines || {};

  // Local state
  const [isAddTimerEnabled, setIsAddTimerEnabled] = useState(false);

  // Transform API reminders data into form data structure
  const formReminders = reminders.map((reminder) => ({
    reminder_id: reminder.reminder_id,
    minutes: reminder.minutes,
    node: detailedOptions.find((option) => option.value === reminder.node_id),
  }));

  // RHF
  const {
    control,
    handleSubmit,
    register,
    watch,
    trigger,
    formState: { isDirty, isSubmitting, errors },
  } = useForm<Form>({
    mode: 'onSubmit',
    // Initialize with default timer if no reminders exist, otherwise use existing reminders
    values: {
      reminders:
        reminders.length === 0 ? [DEFAULT_TIMER_VALUES] : formReminders,
    },
    resolver: yupResolver(reminderFormSchema) as Resolver<Form>,
  });

  const { fields, append, remove, update } = useFieldArray({
    control,
    name: 'reminders',
  });

  const onSubmit = (data: Form) => {
    let reminders: BrainReminder[];
    // If only one empty reminder exists, save as empty array
    if (
      data.reminders.length === 1 &&
      !data.reminders[0].minutes &&
      !data.reminders[0].node
    ) {
      reminders = [];
    } else {
      // Transform form data back to API structure
      reminders = data.reminders
        .map((reminder) => ({
          reminder_id: reminder.reminder_id,
          minutes: +reminder.minutes,
          node_id: reminder.node.value,
        }))
        // Sort by minutes in ascending order
        .sort((a, b) => a.minutes - b.minutes);
    }

    updateBrain({
      brain_id: brainId,
      guidelines: {
        ...guidelines,
        reminders,
      },
    });
  };

  // Format the time display based on minutes
  const getTimerLabel = (minutes: number) => {
    if (minutes >= 60) {
      const hours = Math.floor(minutes / 60);
      return `${hours} ${t('ai_agents.reminder.hours', { count: hours })}`;
    }
    return `${minutes} ${t('rules.minutes')}`;
  };

  const watchTimers = watch('reminders');
  // This error messages is for the complex validation logic for
  // allowing or disallowing empty fields to be validated
  const rootErrorMessage = errors.reminders?.root?.message;

  // Available time options for the dropdown
  const timeOptions = [
    { value: '5', label: getTimerLabel(5) },
    { value: '15', label: getTimerLabel(15) },
    { value: '30', label: getTimerLabel(30) },
    { value: '60', label: getTimerLabel(60) },
    { value: '120', label: getTimerLabel(120) },
    { value: '240', label: getTimerLabel(240) },
    {
      value: '1440',
      label: getTimerLabel(1440),
    },
  ];

  // Check if all timers have both minutes and node selected
  const areTimersValid = useCallback(() => {
    return watchTimers.every((timer) => timer.minutes && timer.node);
  }, [watchTimers]);

  // Validate timer configurations and update add timer button state
  const validateTimers = useCallback(() => {
    const hasReachedMaxTimers = watchTimers.length === 3;

    if (hasReachedMaxTimers) {
      setIsAddTimerEnabled(false);
      return;
    }

    if (areTimersValid()) {
      setIsAddTimerEnabled(true);
    } else {
      setIsAddTimerEnabled(false);
    }
  }, [areTimersValid, watchTimers]);

  // Update add timer button state when reminders change
  useEffect(() => {
    validateTimers();
  }, [fields, validateTimers]);

  useEffect(() => {
    if (dialog) {
      dispatch(setDialog(dialog));
    }

    return () => {
      dispatch(clearDialogNodes());
    };
  }, [dialog, dispatch]);

  return (
    <FormCard onSubmit={handleSubmit(onSubmit)} id={FORM_ID}>
      <FormCard.Header
        title={t('ai_agents.reminder.title')}
        subtitle={t('ai_agents.reminder.subtitle')}
        icon={
          <NumberIcon
            color="var(--icon-default-blue)"
            size="large"
            number={number}
          />
        }
      />
      <FormCard.Content>
        <Typography
          component="h4"
          variant="label-caps-large"
          color="var(--text-default-gray-light)"
          mb="var(--space-8)"
        >
          {t('ai_agents.reminder.timers')}
        </Typography>

        <Box
          component="ul"
          display="flex"
          flexDirection="column"
          gap="var(--space-24)"
        >
          {fields.map((field, index) => {
            const isLastTimer = index + 1 === fields.length;
            const isFirstTimerEmpty =
              index === 0 &&
              !watchTimers[index]?.minutes &&
              !watchTimers[index]?.node;
            // Show remove button if it is the last timer and the first timer is not empty
            const showRemoveButton = isLastTimer && !isFirstTimerEmpty;
            const showPreviewButton = !!watchTimers[index]?.node;

            // Compute selected minutes from other selects (exclude current field)
            const otherSelectedMinutes = watchTimers
              .filter((_, i) => i !== index)
              .map((timer) => String(timer.minutes));

            // Create updated time options with disabled property if already selected elsewhere
            const updatedTimeOptions = timeOptions.map((option) => ({
              ...option,
              disabled: otherSelectedMinutes.includes(option.value),
            }));

            return (
              <li key={field.id}>
                <Box
                  display="flex"
                  flexDirection="row"
                  gap="var(--space-16)"
                  sx={{
                    '& select': {
                      color: !watchTimers[index]?.minutes
                        ? 'var(--text-placeholder-gray)'
                        : 'var(--text-default-black)',
                    },
                    '& > *:nth-child(1)': {
                      marginTop: '10px',
                    },
                    '& > *:nth-child(2)': {
                      maxWidth: 160,
                    },
                    '& > *:nth-child(3)': {
                      flex: 1,
                      maxWidth: 200,
                    },
                  }}
                >
                  <NumberIcon
                    color="var(--icon-default-gray)"
                    size="large"
                    number={index + 1}
                  />
                  <Select
                    placeholder={t('ai_agents.reminder.trigger_after')}
                    options={updatedTimeOptions}
                    {...register(`reminders.${index}.minutes`)}
                    onChange={(e) => {
                      register(`reminders.${index}.minutes`).onChange(e);

                      validateTimers();
                    }}
                    error={!watchTimers[index].minutes && !!rootErrorMessage}
                    errorMessage={
                      !watchTimers[index].minutes && rootErrorMessage
                        ? rootErrorMessage
                        : undefined
                    }
                  />

                  <div>
                    <AutocompleteNew
                      name={`reminders.${index}.node`}
                      control={control}
                      options={detailedOptions}
                      size="medium"
                      hasError={!watchTimers[index].node && !!rootErrorMessage}
                      errorMessage={
                        !watchTimers[index].node && rootErrorMessage
                          ? rootErrorMessage
                          : undefined
                      }
                      placeholder={t('dialog.jump_to.placeholder')}
                      groupByLabelProp={false}
                      groupByProp="dialog_name"
                      renderIconInFront={renderDialogIcon}
                      autoHighlight
                      onChange={() => {
                        validateTimers();
                      }}
                      enableNewEntry={false}
                    />
                  </div>

                  {showPreviewButton && (
                    <Box marginTop="2px">
                      <Button
                        type="button"
                        variant="tertiary"
                        onClick={() => {
                          const dialogId = detailedOptions.find(
                            (option) =>
                              option.value === watchTimers[index].node.value
                          )?.dialog_id;
                          const modalProps = {
                            name: watchTimers[index].node.dialog_name,
                            dialogId,
                          };
                          setFetchDialogId(dialogId);
                          dispatch(setDialogId(dialogId));

                          dispatch(pushModal(MODAL_DIALOG_PREVIEW, modalProps));
                        }}
                      >
                        {t('dialog.actions.preview')}
                      </Button>
                    </Box>
                  )}

                  {showRemoveButton && (
                    <IconButton
                      sx={{
                        marginTop: '8px',
                        alignSelf: 'start',
                      }}
                      onClick={async () => {
                        // If there is only one timer, reset the form
                        if (fields.length === 1) {
                          update(0, DEFAULT_TIMER_VALUES);
                          return;
                        }
                        remove(index);
                        await trigger();
                      }}
                    >
                      <Trash />
                    </IconButton>
                  )}
                </Box>

                {!!watchTimers[index].minutes && !!watchTimers[index].node && (
                  <Typography
                    component="p"
                    variant="label-regular"
                    color="var(--text-default-gray-light)"
                    mt="var(--space-8)"
                    ml="var(--space-36)"
                    minHeight="var(--space-16)"
                  >
                    <>
                      <Trans
                        i18nKey="ai_agents.reminder.helper_text"
                        values={{
                          reminder: getTimerLabel(watchTimers[index].minutes),
                          name: watchTimers[index].node.dialog_name,
                        }}
                      />
                    </>
                  </Typography>
                )}
              </li>
            );
          })}
        </Box>

        <Box mt="var(--space-24)" ml="var(--space-16)">
          <Button
            type="button"
            variant="tertiary"
            onClick={() => {
              append({
                reminder_id: uuidv4(),
                minutes: null,
                node: null,
              });
            }}
            // The form validation is different from the disabled state of the button
            disabled={!isAddTimerEnabled}
          >
            {t('ai_agents.reminder.add_timer')}
          </Button>
        </Box>
      </FormCard.Content>

      <FormCard.Footer>
        <Button
          disabled={!isDirty}
          type="submit"
          variant="secondary"
          isLoading={isSubmitting}
        >
          {t('common.save')}
        </Button>
      </FormCard.Footer>
    </FormCard>
  );
};
