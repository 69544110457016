import { useSelector } from 'react-redux';

import useBrains from '@/hooks/useBrains';
import { selectBrainId } from '@/redux/session/selectors';

import {
  CustomerSupportSetup,
  CustomerSupportSummary,
} from './CustomerSupportSetup';
import { SellerSetup, SellerSummary } from './SellerSetup';
import { useDirtyFormBlocker } from '../../hooks/useDirtyFormBlocker';
import { FormSkeleton, SummarySkeleton } from '../Skeletons/Skeletons';

import styles from './Setup.module.scss';

export const Setup = () => {
  const brainId = useSelector(selectBrainId);
  const { brain, getStatus } = useBrains(brainId);

  // Block the user from navigating to other routes if any form is dirty
  useDirtyFormBlocker();

  const isCustomerSupportBrain = brain?.brain_type === 'support';
  const isDebtCollectionBrain = brain?.brain_type === 'debt_collection';

  const renderMainContent = () => {
    if (getStatus === 'pending') {
      return (
        <>
          <FormSkeleton />
          <FormSkeleton />
          <FormSkeleton />
        </>
      );
    }

    if (isCustomerSupportBrain) {
      return <CustomerSupportSetup />;
    }

    return <SellerSetup isDebtCollection={isDebtCollectionBrain} />;
  };

  const renderAsideContent = () => {
    if (getStatus === 'pending') {
      return <SummarySkeleton />;
    }

    if (isCustomerSupportBrain) {
      return <CustomerSupportSummary />;
    }

    return <SellerSummary isDebtCollection={isDebtCollectionBrain} />;
  };

  return (
    <div className={styles.setup}>
      <div className={styles.setup__main}>{renderMainContent()}</div>

      <div className={styles.setup__summary}>{renderAsideContent()}</div>
    </div>
  );
};
