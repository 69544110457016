import Box from '@mui/material/Box/Box';
import Typography from '@mui/material/Typography';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import Checkmark from '@/components/atoms/Icons/Checkmark';
import Select from '@/components/atoms/Select/Select';
import useBrains from '@/hooks/useBrains';
import useCollections from '@/hooks/useCollections';
import { selectBrainId } from '@/redux/session/selectors';

import { StaticKnowledgeIcon } from '../../icons/StaticKnowledge';

import styles from './Knowledge.module.scss';

interface Form {
  collection_id: string;
}

export const SummaryFooter = () => {
  // Redux selectors
  const brainId = useSelector(selectBrainId);

  // Custom hooks
  const { t } = useTranslation();
  const { brain, updateBrain } = useBrains(brainId);
  const { collection, collectionsByLanguage } = useCollections(
    brain?.collection_id
  );

  const { register } = useForm<Form>({
    mode: 'onChange',
    values: {
      collection_id: collection ? collection.collection_id : '',
    },
  });

  // Local variables
  const collections = collectionsByLanguage(brain?.language) || [];
  const collectionOptions = collections.map((collection) => {
    return {
      value: collection.collection_id,
      label: collection.name,
    };
  });

  const onChangeCollection = (event: React.ChangeEvent<HTMLSelectElement>) => {
    // Return null when 'None' is selected
    const newCollectionId = event.target.value || null;

    updateBrain({
      brain_id: brainId,
      collection_id: newCollectionId,
    });
  };

  return (
    <Box className={styles.summaryFooter}>
      <Box display="flex" flexDirection="column" gap="var(--space-4)">
        <Box display="flex" gap="var(--space-4)" alignItems="center">
          {collection ? (
            <Checkmark />
          ) : (
            <StaticKnowledgeIcon color="var(--icon-default-gray)" />
          )}

          <Typography
            component="h4"
            variant="label-caps-large"
            color="var(--text-default-gray)"
          >
            {t('ai_agents.knowledge.static_knowledge')}
          </Typography>
        </Box>

        <Typography
          component="p"
          variant="label-regular"
          color="var(--text-default-gray)"
        >
          {t('ai_agents.knowledge.static_knowledge_tooltip')}
        </Typography>
      </Box>

      <Box flexGrow={1} minWidth={174}>
        <Select
          size="full"
          options={collectionOptions}
          {...register('collection_id')}
          onChange={onChangeCollection}
          enablePlaceholder
          placeholder={t('common.none')}
        />
      </Box>
    </Box>
  );
};
